import { useRef, useState, useCallback, useEffect } from 'react';

import IconSearch from '../icon/IconSearch';
import SearchDialog from '../modal/SearchDialog';

import { useGlobalStore } from '../context/global';
import { useSearchEvents } from '../hook/useSearchEvents';

const searchMode = {
  Normal: 0,
  Simple: 1,
};

const Search = ({ mode = searchMode.Simple, catalog = false }) => {
  const [globalState, globalDispatch] = useGlobalStore();
  const [isOpen, setIsOpen] = useState(false);
  const refSearchButton = useRef(null);
  const macOS = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onInput = useCallback(
    (event) => {
      setIsOpen(true);
      // setInitialQuery(event.key);
    },
    // [setIsOpen, setInitialQuery]
    [setIsOpen]
  );

  useSearchEvents({
    isOpen,
    onOpen,
    onClose,
    onInput,
    refSearchButton,
  });

  const searchClear = (e) => {
    e && e.stopPropagation();
    globalDispatch.setSearch('');
  };

  const searchAction = () => {
    // globalState.search.length > 0 ? searchClear() : onOpen();
    onOpen();
  };

  useEffect(() => {
    return () => {
      console.log("onClose");
      onClose();
    }
  }, []);

  return (
    <div className="relative max-w-400">
      {/* SEARCH BUTTON NEW */}
      <button
        type="submit"
        className={`${globalState.search.length > 0 ? 'active' : ''} flex 
          items-center rounded-md p-2 text-gray-800 hover:text-primary hover:cursor-pointer group`}
        onClick={searchAction}
        data-cy="search-button-new"
        ref={refSearchButton}
      >
        <span className='hidden md:block border bg-white border-gray-500 px-1 mr-2 rounded-md text-xs group-hover:text-primary'>
          {macOS ? <span className='relative top-px inline-block text-base leading-3'>⌘</span> : 'Ctrl+'}K
        </span>
        <IconSearch size={8} className='group-hover:text-primary' />
        <p 
          className={`${globalState.search.length > 0 ? '' : 'invisible'} relative top-0 right-0 place-self-start`}
          onClickCapture={searchClear}
        >
          <span className="flex justify-center text-center">
            <span 
              className="absolute top-0 -right-3 block rounded-full p-0.5 text-xs font-sans font-bold text-white 
              bg-red-500 hover:bg-black" 
              style={{ minWidth: "17px", height: "17px", paddingTop: "1px"}}
            >
              ✖
            </span>
          </span>
        </p>
      </button>

      {/* SEARCH DIALOG */}
      {isOpen && <SearchDialog uniqueKey={"search_dialog"} onClose={onClose} />}
    </div>
  );
};

export default Search;
