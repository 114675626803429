import React from "react";

const useFocusOnMount = (ref) => {
  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);
};

export default useFocusOnMount;
